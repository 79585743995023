<template>
  <div id="locale-select-dropdown-wrapper">
    <b-dropdown
        v-model="currentLocale"
        variant="light"
        class="mr-2 mt-1"
        dropleft text="Drop-Left"
        no-caret
    >
      <div slot="button-content" v-if="currentFlag">
        <img :src="require(`../../../assets/img/flag/` + currentFlag)" class="icon icon-flag"/>
      </div>
      <b-dropdown-item
          v-for="(obj, i) in listLocales"
          :key="`locale-${i}`"
          :value="obj.locale"
          @click="switchLanguage(obj.locale)"
      >
        <div class="d-flex">
          <img :src="require(`../../../assets/img/flag/` + obj.flagImgName)" class="icon icon-flag"/>
          <span class="text-secondary ml-2">{{ obj.label }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLocale: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'fr_fr',
    }
  },
  methods: {
    switchLanguage(locale) {
      this.currentLocale = locale
      this.$emit('emit-locale-selected', { locale: locale })
    }
  },
  computed: {
    currentFlag() {
      const index = this.listLocales.findIndex(x => x.locale === this.currentLocale)
      return this.listLocales[index].flagImgName
    },
    listLocales() {
      return this.$store.getters.getLocalesListGetter
    },
  }
}
</script>